import { PillColumn } from '@app/modules/table/classes/pill-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PillDisplay } from '@app/modules/table/interfaces/pill-display.interface';
import { Office } from '@models/company/office.model';

export class AddressColumn extends PillColumn<Office> {
    title = 'company.offices.addressColumn';
    property = 'addressLine1';
    sortable = true;
    type: CellDisplay = CellDisplay.pill;
    displayWidth = 40;
    primaryAddressId: number;

    getPillDisplay(office: Office): PillDisplay | undefined {
        if (this.primaryAddressId != office.id) {
            return;
        }
        return {
            type: 'success',
            label: 'company.offices.primaryLabel',
        };
    }

    getCellText(office: Office): string | null {
        return office.addressLine1;
    }

    setPrimaryAddressId(id: number) {
        this.primaryAddressId = id;
    }
}
